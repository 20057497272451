import { DashboardSaleChart } from "../chart/DashboardSaleChart";


function HomePage(){

    return(
        <div>
            <DashboardSaleChart />
        </div>
    )
}

export default HomePage;