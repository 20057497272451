
function OrderPageDash(){

    return(
        <div>
            <h1>OrderPageDash</h1>
        </div>
    )
}

export default OrderPageDash;