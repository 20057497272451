import React from 'react'

function ProvincePageDash() {
  return (
    <div>
      <h1>ProvincePageDash</h1>
    </div>
  )
}

export default ProvincePageDash
