import React from 'react'

function SoldByCategoryPage() {
  return (
    <div>
      <h1>SoldByCategoryPage</h1>
    </div>
  )
}

export default SoldByCategoryPage
