import React from 'react'

function SoldByProductPageDash() {
  return (
    <div>
      <h1>SoldByProductPageDash</h1>
    </div>
  )
}

export default SoldByProductPageDash
