import UploadAntd from "../component-dash/upload/UploadAntd";

function CustomerPageDash(){

    return(
        <div>
            <h1>CustomerPageDash</h1>
            <UploadAntd />
        </div>
    )
}

export default CustomerPageDash;