

function CustomerPage () {

    return (
        <div>
            <h1>Customer</h1>
        </div>
    )
}

export default  CustomerPage