import React from 'react'

function RolePageDash() {
  return (
    <div>
       <h1>RolePageDash</h1>
    </div>
  )
}

export default RolePageDash
