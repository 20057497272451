import ProductList from "../../component/list/ProductList"
function AboutPage (){

    return (
        <div>
            <h1>AboutPage</h1>
            <ProductList />
        </div>
    )
}

export default AboutPage