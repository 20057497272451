

function ProductPage(){

    return (
        <div>
            <h1>ProductPage</h1>
        </div>
    )
}

export default ProductPage;