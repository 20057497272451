
function CategoryPage(){

    return (
        <div>
            <h1>CategoryPage</h1>
        </div>
    )
}

export default CategoryPage;