import React from 'react'

function TopSalePageDash() {
  return (
    <div>
      <h1>TopSalePageDash</h1>
    </div>
  )
}

export default TopSalePageDash
