import React from 'react'

function OrderStatusPageDash() {
  return (
    <div>
       <h1>OrderStatusPageDash</h1>
    </div>
  )
}

export default OrderStatusPageDash
