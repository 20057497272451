import React from 'react'

function OrderPaymentPageDash() {
  return (
    <div>
      <h1>OrderPaymentPageDash</h1>
    </div>
  )
}

export default OrderPaymentPageDash
