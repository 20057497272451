import React from 'react'

function RegisterDashBoard() {
  return (
    <div>
      <h1>Register</h1>
    </div>
  )
}

export default RegisterDashBoard
